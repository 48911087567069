import { useQuery } from '@tanstack/react-query';
import config from 'src/config';
import { getCompleteMenu } from 'src/services/api';
import normalizeText from 'src/utils/normalizeText';

function useCompleteMenu(storeID, selectedCategoryType) {
  const {
    data: menu = { menuCategories: [] },
    isLoading,
    isError,
  } = useQuery(
    ['getCompleteMenu', storeID, selectedCategoryType],
    async () => {
      const calculatedCategory = selectedCategoryType || config.defaultCategory;

      return getCompleteMenu(storeID, calculatedCategory);
    },
    {
      enabled: !!storeID,
      refetchOnWindowFocus: false,
    },
  );

  return { menu, isLoading, isError };
}

export function useMenu({ storeID, searchValue = '', selectedCategoryType }) {
  const {
    menu: completeMenu,
    isLoading: isMenuLoading,
    isError: isMenuError,
  } = useCompleteMenu(storeID, selectedCategoryType);

  if (isMenuLoading || isMenuError) {
    return { menu: { menuCategories: [] }, isLoading: isMenuLoading, isError: isMenuError };
  }

  if (!searchValue.trim()) {
    return { menu: completeMenu };
  }

  const filterProducts = [];
  completeMenu.menuCategories?.forEach((category) => {
    const filteredProducts = category.products.filter((product) => {
      const searchNormalized = normalizeText({ text: searchValue });

      const matchName = normalizeText({
        text: product.name,
        searchTerm: searchNormalized,
      });

      const matchCategory = product.categoryGroup?.name
        ? normalizeText({
          text: product.categoryGroup.name,
          searchTerm: searchNormalized,
        })
        : false;

      return matchName || matchCategory;
    });

    if (filteredProducts.length > 0) {
      filterProducts.push({
        ...category,
        products: filteredProducts,
      });
    }
  });

  return {
    menu: {
      ...completeMenu,
      menuCategories: filterProducts.sort((a, b) => a.index - b.index),
    },
  };
}

export default useMenu;
