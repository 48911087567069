import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import InputContainer from '../InputContainer';
import InputCounterNumber from '.';

function InputCounterNumberControlled(props) {
  const { control } = useFormContext();
  const {
    label,
    name,
    helper,
    min,
    max,
    step,
    fullWidth,
    classes,
    readOnly,
    disabled,
    direction,
    hiddenOptions,
    allowMinValue,
    ...args
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: {
          onChange,
          value,
        },
      }) => (
        <InputContainer
          label={label}
          helper={helper}
          direction={direction}
          fullWidth={fullWidth}
          className={clsx(classes)}
          hiddenOptions={hiddenOptions}
          allowMinValue={allowMinValue}
          onContainerClick={args.onContainerClick}
        >
          <InputCounterNumber
            {...args}
            className={clsx(classes)}
            value={value}
            min={min}
            max={max}
            step={step}
            fullWidth={fullWidth}
            onChange={onChange}
            readOnly={readOnly}
            disabled={disabled}
          />
        </InputContainer>
      )}
    />
  );
}

InputCounterNumberControlled.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  helper: PropTypes.node,
  classes: PropTypes.array,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  direction: PropTypes.oneOf(['column', 'row', 'row-reverse']),
  hiddenOptions: PropTypes.node,
  allowMinValue: PropTypes.bool,
};

InputCounterNumberControlled.defaultProps = {
  label: null,
  helper: null,
  classes: [],
  readOnly: false,
  disabled: false,
  fullWidth: false,
  min: 0,
  max: 99,
  step: 1,
  direction: 'column',
  hiddenOptions: null,
  allowMinValue: false,
};

export default InputCounterNumberControlled;
