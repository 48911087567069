import { Outlet } from 'react-router-dom';
import SchedulerContext from '../../contexts/Scheduler.context';

function StoreLayout() {
  return (
    <SchedulerContext>
      <Outlet />
    </SchedulerContext>
  );
}

export default StoreLayout;
