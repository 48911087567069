import clsx from 'clsx';
import PropTypes from 'prop-types';
import { cloneElement, useEffect, useRef } from 'react';
import config from '../../config';

function getClientEvent(event) {
  if (event.touches && event.touches.length > 0) {
    return event.touches[0];
  }

  return event;
}

function InputContainer({
  id,
  label,
  helper,
  direction,
  children,
  fullWidth,
  className,
  fieldState,
  hiddenOptions,
  showOptions,
  allowMinValue,
  name,
  multiValueInput,
  onContainerClick,
}) {
  const containerRef = useRef(null);
  const optionsRef = useRef(null);
  const wrapperOptionsRef = useRef(null);

  const { error, invalid, isDirty } = fieldState;

  let dragStart = false;
  let optionsWidth = 0;
  let dragWidth = 0;
  let startX = 0;
  let dragX = 0;

  const handleDragOuter = () => {
    if (!hiddenOptions) {
      return;
    }

    const optionsStyle = window.getComputedStyle(optionsRef.current, null);
    const width = Math.round(optionsStyle.getPropertyValue('width').replace(/[^\d.]+/g, ''));
    const isHover = containerRef.current.matches(':hover') || optionsRef.current.matches(':hover');
    const shouldHide = optionsRef.current.matches(':hover');

    if (!isHover && width > 0) {
      containerRef.current.style.marginLeft = '0px';
      optionsRef.current.style.width = '0px';
    }

    if (isHover && shouldHide) {
      setTimeout(() => {
        if (containerRef?.current) {
          containerRef.current.style.marginLeft = '0px';
          optionsRef.current.style.width = '0px';
        }
      }, 250);
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleDragOuter);
    window.addEventListener('touchstart', handleDragOuter);

    return () => {
      window.removeEventListener('mousedown', handleDragOuter);
      window.removeEventListener('touchstart', handleDragOuter);
    };
  }, [optionsRef, hiddenOptions]);

  const handleDragStart = (event) => {
    if (!hiddenOptions) {
      return;
    }

    const wrapperStyle = window.getComputedStyle(wrapperOptionsRef.current, null);
    const optionsStyle = window.getComputedStyle(optionsRef.current, null);

    optionsWidth = Math.round(wrapperStyle.getPropertyValue('width').replace(/[^\d.]+/g, ''));
    dragWidth = Math.round(optionsStyle.getPropertyValue('width').replace(/[^\d.]+/g, ''));

    containerRef.current.style.cursor = 'grabbing';
    dragStart = true;
    startX = getClientEvent(event).clientX;
    dragX = 0;
  };

  const handleDragMove = (event) => {
    if (dragStart) {
      dragX = startX - getClientEvent(event).clientX;
      containerRef.current.style.marginLeft = `${Math.max(Math.min(-(dragWidth + dragX), 0), -optionsWidth)}px`;
      optionsRef.current.style.width = `${Math.min(Math.max(dragWidth + dragX, 0), optionsWidth)}px`;
    }
  };

  const handleDragRelease = () => {
    if (!dragStart) {
      return;
    }

    dragStart = false;
    containerRef.current.style.cursor = 'grab';
    if (Math.abs(dragX) > config.dragWidth) {
      dragWidth = dragX > 0 ? optionsWidth : 0;
    }

    containerRef.current.style.marginLeft = `${-dragWidth}px`;
    optionsRef.current.style.width = `${dragWidth}px`;
  };

  const handleForceShowOptions = (open) => {
    if (!hiddenOptions) {
      return false;
    }

    if (containerRef) {
      const width = open ? optionsWidth : 0;
      containerRef.current.style.marginLeft = `${-width}px`;
      optionsRef.current.style.width = `${width}px`;
    }

    return allowMinValue;
  };

  useEffect(() => {
    handleForceShowOptions(showOptions);

    return () => null;
  }, [showOptions, containerRef]);

  const mainContent = clsx('flex relative z-10 transition-all cart__product-wrapper', className, {
    'w-full': fullWidth,
    'justify-start flex-col': direction === 'column',
    'flex-row items-center': ['row', 'row-reverse'].includes(direction),
    'cursor-grab': hiddenOptions,
    'cursor-default': !hiddenOptions,
  });

  return (
    <>
      <div
        className={mainContent}
        onMouseDown={handleDragStart}
        onMouseMove={handleDragMove}
        onMouseUp={handleDragRelease}
        onMouseLeave={handleDragRelease}
        onTouchStart={handleDragStart}
        onTouchMove={handleDragMove}
        onTouchEnd={handleDragRelease}
        ref={containerRef}
        role="button"
        tabIndex={-1}
        onClick={onContainerClick}
        onKeyDown={(e) => e.key === 'Enter' && onContainerClick()}
      >
        {label && ['column', 'row'].includes(direction) && (
          <label className="label__wrapper" htmlFor={id}>
            {label}
          </label>
        )}
        {cloneElement(children, {
          onConfirmOptions: handleForceShowOptions,
        })}
        {label && direction === 'row-reverse' && (
          <label className="label__wrapper" htmlFor={id}>
            {label}
          </label>
        )}
      </div>
      {hiddenOptions && (
        <div
          className="absolute right-0 h-full z-0 overflow-x-hidden transition-all"
          style={{ width: 0 }}
          ref={optionsRef}
        >
          <div className="absolute right-0 min-w-max h-full" ref={wrapperOptionsRef}>
            {hiddenOptions}
          </div>
        </div>
      )}

      {helper && !(invalid && isDirty) && <span className="text-sm mt-2">{helper}</span>}
      {invalid && isDirty && !multiValueInput && (
        <span
          className="text-sm mt-2 text-baco-error-normal"
          id={`error-${name}`}
        >
          {error?.message ?? null}
        </span>
      )}
    </>
  );
}

InputContainer.propTypes = {
  name: PropTypes.string,
  label: PropTypes.node,
  helper: PropTypes.node,
  direction: PropTypes.oneOf(['column', 'row', 'row-reverse']),
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  fieldState: PropTypes.any,
  hiddenOptions: PropTypes.node,
  showOptions: PropTypes.bool,
  allowMinValue: PropTypes.bool,
  multiValueInput: PropTypes.bool,
  id: PropTypes.string,
  onContainerClick: PropTypes.func,
};

InputContainer.defaultProps = {
  name: '',
  label: null,
  helper: null,
  direction: 'column',
  fullWidth: false,
  className: '',
  fieldState: {},
  hiddenOptions: null,
  showOptions: false,
  allowMinValue: false,
  multiValueInput: false,
  id: '',
  onContainerClick: () => null,
};

export default InputContainer;
