import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ElectricBoldIcon } from '../icons';

function StorePicked(props) {
  const {
    imageUrl,
    name,
    address,
    hasTurbo,
  } = props;

  const contentWrapper = clsx(
    'flex flex-col max-w-[calc(100%-127px)] h-full px-3 py-3 rounded-l-lg',
    'grow bg-baco-secondary text-baco-primary',
  );

  return (
    <div className="flex w-full items-stretch">
      <div className={contentWrapper}>
        <div className="flex justify-between items-start mb-1">
          <h3 className="text-lg font-bold leading-5 m-0">
            <span className="block">
              Estás ordenando en
            </span>
            <span className="flex">
              <span className="">{name}</span>
              { hasTurbo ? <ElectricBoldIcon className="fill-yellow-500 h-5" /> : '' }
            </span>
          </h3>
        </div>
        <hr className="w-7/12 h-[1px] border-none my-3 bg-baco-primary" />
        <p className="text-base leading-4 truncate">
          {address}
        </p>
      </div>
      <div
        className="min-w-[127px] bg-gray-400 bg-cover bg-center rounded-r-lg grow"
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
    </div>
  );
}

StorePicked.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string,
  imageUrl: PropTypes.string,
  hasTurbo: PropTypes.bool,
};
StorePicked.defaultProps = {
  imageUrl: null,
  name: '',
  address: '',
  hasTurbo: false,
};

export default StorePicked;
