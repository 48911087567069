import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLogin } from 'src/contexts/Login.context';
import useDelivery from 'src/hooks/actions/useDelivery';
import CategoryScroller from 'src/utils/CategoryScroller';
import '../../styles/Header.css';
import CartButton from '../CartButton';
import LocationInfo from '../LocationInfo';
import SearchBar from '../SearchBar/SearchBar';
import Skeleton from '../Skeleton';
import BackButton from '../BackButton';
import HeaderLocation from './HeaderLocation';
import ChevronLeft from '../icons/ChevronLeft';
import LogoButton from '../LogoButton';

const DIRECTION = {
  START: 'left',
  END: 'right',
};
// TODO: add states for direction buttons disabled and hidden
function CommonHeader({
  currentLocation, onGoToStores, onGoToCart, endComponent, isLoading, menu, onSearch, isStore,
}) {
  const { items = [] } = useSelector((state) => state.cart);
  const total = items.reduce((sum, item) => sum + item.count, 0);
  const { deliveryData } = useDelivery();
  const [openAddressDrawer, setOpenAddressDrawer] = useState(false);
  const navigate = useNavigate();
  const { token } = useLogin();

  const { scrollToCategory } = CategoryScroller;

  const handleCart = () => {
    navigate('cart', { relative: 'path' });
  };

  const scrollCategories = ({ direction }) => {
    const container = document.querySelector('.categoryName__list');
    if (container) {
      const scrollAmount = direction === DIRECTION.START ? -container.offsetWidth / 2 : container.offsetWidth / 2;

      container.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  if (isLoading && isStore) {
    return (
      <header className="header" id="header" data-is-store={isStore}>
        <div className="header__wrapper items-center">
          <Skeleton className="h-12 w-1/2 cart__location__wrapper" />
          <LogoButton />
          <Skeleton className="!h-9 !mr-2 header__search-bar" />
          <Skeleton className="!h-6 w-6 header__cart__button" />
          <Skeleton className="header__category-wrapper !h-12 !w-full md:!w-1/2 !my-2 md:!my-3" />
          <Skeleton className="h-12 categoryName" />
        </div>
      </header>
    );
  }

  if (isLoading && !isStore) {
    return (
      <header className="header" id="header" data-is-store="false">
        <div className="header__wrapper items-center">
          <Skeleton className="!h-9 !mr-2 header__back-button" />
          <LogoButton />
          <Skeleton className="header__location-store !h-8 !w-1/2 !mr-2" />
          <Skeleton className="!h-6 w-6 header__cart__button" />
        </div>
      </header>
    );
  }

  return (
    <header className="header" id="header" data-is-store={isStore}>
      <div className="header__wrapper">
        {isStore ? (
          <LocationInfo
            isDelivery={deliveryData.isDelivery}
            storeName={currentLocation?.name}
            address={deliveryData.address}
            onClick={deliveryData.isDelivery ? () => setOpenAddressDrawer(true) : onGoToStores}
            isStore
            openAddressDrawer={openAddressDrawer}
            setOpenAddressDrawer={setOpenAddressDrawer}
            token={token}
            navigate={navigate}
          />
        ) : (
          <BackButton
            onClick={() => navigate(`/store/${currentLocation?.storeId}`)}
            title={`Volver a ${currentLocation?.name}`}
          />
        )}
        <LogoButton />
        {endComponent}

        {isStore ? (
          <SearchBar onChange={onSearch} placeholder="Buscar productos" />
        ) : (
          <HeaderLocation
            title={deliveryData.isDelivery ? deliveryData.address : currentLocation?.name}
            onClick={deliveryData.isDelivery ? () => navigate('/MyAddress') : onGoToStores}
          />
        )}
        <CartButton total={total} onGoToCart={onGoToCart ?? handleCart} />
        <div className="categoryName">
          <button
            type="button"
            onClick={() => scrollCategories({ direction: DIRECTION.START })}
            className="scroll-button scroll-button--left"
            aria-label="Categorías anteriores"
            title="Categorías anteriores"
            data-show={menu.length > 0}
          >
            <ChevronLeft />
          </button>
          <ul className="categoryName__list" hidden={menu.length === 0}>
            {menu.length > 0
              && menu.map(({ id, label }) => (
                <li key={id} className="categoryName__list__wrapper">
                  <button
                    type="button"
                    aria-current={window.location.hash === `#${id}` ? 'page' : undefined}
                    onClick={() => {
                      scrollToCategory({ id });
                      window.location.hash = id;
                    }}
                    className="menu__item"
                    data-category-link={id.toString()}
                    data-text={label}
                  >
                    {label}
                  </button>
                </li>
              ))}
          </ul>
          <button
            type="button"
            onClick={() => scrollCategories({ direction: DIRECTION.END })}
            className="scroll-button scroll-button--right"
            aria-label="Categorías siguientes"
            title="Categorías siguientes"
            data-show={menu.length > 0}
          >
            <ChevronLeft />
          </button>
        </div>
      </div>
    </header>
  );
}

CommonHeader.propTypes = {
  currentLocation: PropTypes.any,
  onGoToStores: PropTypes.func,
  onGoToCart: PropTypes.func,
  endComponent: PropTypes.node,
  isLoading: PropTypes.bool,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  onSearch: PropTypes.func,
  isStore: PropTypes.bool,
};

CommonHeader.defaultProps = {
  currentLocation: {},
  onGoToStores: () => null,
  onGoToCart: null,
  endComponent: null,
  isLoading: false,
  menu: [],
  onSearch: () => null,
  isStore: false,
};

export default CommonHeader;
