import PropTypes from 'prop-types';
import formatCurrency from 'src/utils/currency';
import ProductTags from './ProductTags';

function ProductPrice({
  price, discountedPrice, tags, total, hasDescription,
}) {
  const getDisplayPrice = () => {
    if (discountedPrice && discountedPrice < price) {
      return discountedPrice;
    }

    if (total === 0) {
      return price;
    }

    return total;
  };

  const showDiscountedPrice = discountedPrice && discountedPrice < price;

  return (
    <div className="product__price" data-has-description={hasDescription}>
      {showDiscountedPrice ? <span className="product__price--discounted">{formatCurrency(price)}</span> : null}

      <span className="product__price--original">{formatCurrency(getDisplayPrice())}</span>

      {tags?.length > 0 && showDiscountedPrice && (
        <ProductTags discountedPrice={discountedPrice} price={price} tags={tags} />
      )}
    </div>
  );
}

export default ProductPrice;

ProductPrice.defaultProps = {
  tags: [],
  discountedPrice: null,
  price: 0,
  total: 0,
  hasDescription: false,
};

ProductPrice.propTypes = {
  tags: PropTypes.array,
  price: PropTypes.number,
  discountedPrice: PropTypes.number,
  total: PropTypes.number,
  hasDescription: PropTypes.bool,
};
