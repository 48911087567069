import PropTypes from 'prop-types';
import Fuse from 'fuse.js';

function normalizeText({ text, searchTerm }) {
  const normalizedText = text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim();

  if (searchTerm) {
    const fuse = new Fuse([normalizedText], {
      includeScore: true,
      threshold: 0.4, // 0 too strict, 1 too permissive
      distance: 100,
    });

    const result = fuse.search(searchTerm);

    return result.length > 0;
  }

  return normalizedText;
}

export default normalizeText;

normalizeText.propTypes = {
  text: PropTypes.string,
  searchTerm: PropTypes.string,
};

normalizeText.defaultProps = {
  text: '',
  searchTerm: null,
};
