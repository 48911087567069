import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import Button from '../../components/Button';
import { BasketIcon, OpenBasketIcon } from '../../components/icons';
import { formatCurrency } from '../../utils/currency';

function AddToCartButton({
  form, product, minRequirements, startAnimation, onAnimationEnd, isEdit,
}) {
  const handleAddProductComplete = () => setTimeout(onAnimationEnd, 250);

  const renderSubmitButton = () => {
    if (!product.isAvailable) {
      return <span>Producto No Disponible</span>;
    }

    return (
      <span className="flex justify-center items-center w-full">
        {!startAnimation ? <BasketIcon className="mr-3" /> : <OpenBasketIcon className="mr-3" />}
        <span className="flex items-center justify-center">
          {!isEdit && <span className="mr-2">{!startAnimation ? 'Agregar' : 'Agregado'}</span>}
          {isEdit && <span className="mr-2">{!startAnimation ? 'Editar' : 'Editado'}</span>}
          <motion.span
            className="block overflow-hidden whitespace-nowrap"
            onAnimationComplete={handleAddProductComplete}
            initial={{
              opacity: 1,
              maxWidth: 100,
            }}
            animate={
              startAnimation
                ? {
                  opacity: 0,
                  maxWidth: 0,
                  transition: {
                    duration: 0.5,
                  },
                }
                : {}
            }
          >
            {`| ${formatCurrency(product.total)}`}
          </motion.span>
        </span>
      </span>
    );
  };

  return (
    <Button type="submit" disabled={!minRequirements || !product.isAvailable} size="lg" form={form} fullWidth>
      {renderSubmitButton()}
    </Button>
  );
}

AddToCartButton.propTypes = {
  form: PropTypes.string,
  product: PropTypes.any.isRequired,
  minRequirements: PropTypes.bool.isRequired,
  startAnimation: PropTypes.bool.isRequired,
  onAnimationEnd: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

AddToCartButton.defaultProps = {
  form: '',
  isEdit: false,
};

export default AddToCartButton;
